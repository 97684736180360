const api = {  
 mesURL: "https://mes.systemtd.com", 
 baseURL: "https://booking.systemtd.com",
 

  // mesURL: "http://localhost:4000",
  // baseURL: "http://localhost:4005"

  
 
} 

export default api