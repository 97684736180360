import http_common from './http-common'
import http from './http-mes'
import http_file from './http-mes-file'

const dataService = {
    member: {
        get: (id) => http.get(`/member/${id}`),
        getByEmail: (email) => http.get(`/member/getByEmail?email=${email}`),
        list: () => http.get('/member'),
    },

    project: {
        list: () => http.get('/project')
    },

    machine: {       
        machineList: async () => {
            const response = await http.get('/machine');
            return response.data;
        },
    },

    booking: {
        create: (data) => http.post(`/booking`, data),
        update: (id, data) => http.put(`/booking/${id}`, data),
        destroy: (id) => http.delete(`/booking/${id}`),
        listBetweenDate: (startDate, endDate, projectId) => http.get(`/booking/listBetweenDate/${startDate}/${endDate}/${projectId}`),
        listUpdatedBy: (email) => http.get(`/booking/listUpdatedBy?email=${email}`)
    },

    fileServer: {
        upload: (data) => http_file.post('/fileServer/upload', data),       
        download: (data) => http_file.get(`/fileServer/download?item=${data}`, { responseType: 'blob' }),  
        downloadImg: (data) => http_file.get(`/fileServer/downloadImg?item=${data}`),  
    },

    coordinates: {
        create: (data) => http.post(`/coordinates`, data),
    },

    photo: {
        create: (data) => http.post(`/photo`, data),
        listByBookingId: (bookingId) => http.get(`/photo/listByBookingId?bookingId=${bookingId}`)
    },

    report: {
        listBetweenDate: (startDate, endDate) => http.get(`/bookingReport/listBetweenDate/${startDate}/${endDate}`),
        listBetweenDateCollectionPoint: (startDate, endDate) => http.get(`/bookingReport/listBetweenDateCollectionPoint/${startDate}/${endDate}`),
        listByDateNow: () => http.get(`/bookingReport/listByDateNow`),
       
    },

    notify: {
        sendMessage: (data) => http_common.post(`/notify`, data)
    }
    
}

export default dataService